<template>
  <div>
    <nav
      class="flex items-center sm:justify-between justify-end py-6 sm:px-4 md:px-8 px-8 sm:py-4 uppercase fixed bg-gray-900 z-50 w-full"
      :class="{hidden: scrollPosition < 300, block: scrollPosition > 300}"
    >
      <div
        :class="open ? 'block': 'hidden'"
        class="w-full flex-grow sm:flex sm:items-center sm:w-auto"
      >
        <div class="text-sm sm:flex-grow">
          <a href="/" class="no-underline block mt-4 sm:inline-block sm:mt-0 mr-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              viewBox="0 0 24 24"
              class="icon-home w-10 text-base inline-block"
            >
              <path
                d="M21 13v10h-6v-6h-6v6h-6v-10h-3l12-12 12 12h-3zm-1-5.907v-5.093h-3v2.093l3 3z"
              />
            </svg>
          </a>
          <a href="#about" class="no-underline block mt-4 sm:inline-block sm:mt-0 mr-4">About</a>
          <a
            href="#portfolio"
            class="no-underline block mt-4 sm:inline-block sm:mt-0 mr-4"
          >Portfolio</a>
          <a href="#contact" class="no-underline block mt-4 sm:inline-block sm:mt-0 mr-4">Contact</a>
        </div>
      </div>

      <div class="sm:visible invisible">
        <a href="https://www.linkedin.com/in/andrenakazato/" target="_blank">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            class="icon-linkedin"
          >
            <path
              d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"
            />
          </svg>
        </a>
      </div>

      <div class="block sm:hidden">
        <button @click="toggle" class="flex items-center px-3 py-2">
          <svg class="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      scrollPosition: null,
      open: false
    };
  },

  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    toggle() {
      this.open = !this.open;
    }
  },

  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  }
};
</script>

<style scoped>
.icon-home,
.icon-linkedin {
  fill: white;
}
</style>
