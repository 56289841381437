<template>
  <section id="portfolio" class="py-16 bg-gray-700">
    <div class="mx-auto px-8 md:px-16 max-w-screen-xl relative z-10">
      <h2 data-aos="slide-up" data-aos-duration="250" class="text-4xl py-4 uppercase">Portfolio</h2>
      <div class="flex flex-wrap -mx-3 pt-8">
        <PortfolioCard
          v-for="(portfolio, i) in portfolio"
          :key="i"
          :portfolio="portfolio"
          class="w-full md:w-1/2 lg:w-1/3 px-3 flex flex-col"
        />
      </div>
    </div>
  </section>
</template>

<script>
import PortfolioCard from "../components/PortfolioCard";
import portfolio from "../components/Portfolio.json";

export default {
  components: {
    PortfolioCard
  },
  data: () => ({
    portfolio: portfolio
  })
};
</script>

<style lang="scss"></style>
