<template>
  <div class="portfolio-item">
    <a
      :href="portfolio.url"
      class="no-underline transition flex-1 flex flex-col overflow-hidden"
      target="_blank"
    >
      <div class="p-6">
        <h4 class="my-2 font-semibold text-lg leading-tight">
          {{ portfolio.title }}
        </h4>

        <div class="flex items-baseline pb-3">
          <div
            class="mr-2 text-gray-600 text-xs uppercase font-semibold tracking-wide"
            v-for="(tag, i) in portfolio.tags"
            :key="`tag-${i}`"
          >
            {{ tag }}
          </div>
        </div>
        <p class="inline-flex items-center">
          <span class="text-grey-dark text-sm mr-2">{{ portfolio.url }}</span>
          <svg
            class="h-4 w-4 text-grey fill-current"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
          >
            <path
              d="M14 3.41l-7.3 7.3a1 1 0 0 1-1.4-1.42L12.58 2H9a1 1 0 1 1 0-2h6a1 1 0 0 1 1 1v6a1 1 0 0 1-2 0V3.41zM12 11a1 1 0 0 1 2 0v3a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4c0-1.1.9-2 2-2h3a1 1 0 1 1 0 2H2v10h10v-3z"
            />
          </svg>
        </p>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  props: ['portfolio']
};
</script>

<style lang="scss">
.portfolio-item {
  background-size: 100% 200%;
  background-image: linear-gradient(to bottom, transparent 50%, black 50%);
  transition: background-position 0.1s;
  &:hover {
    background-position: 0 100%;
  }
}
</style>
